<template>
   <div>
      <h2 class="content-block">Транспорт на территории</h2>

      <dx-data-grid
         class="dx-card wide-card"
         key-expr="N"
         :data-source="dataSource"
         :focused-row-index="0"
         :show-borders="true"
         :focused-row-enabled="true"
         :column-auto-width="true"
         :column-hiding-enabled="false"
         @cell-prepared="onCellPrepared"
         @exporting="onExporting"
      >
         <dx-scrolling mode="standard" column-rendering-mode="virtual" />
         <dx-export :enabled="true" :allow-export-selected-data="false" />
         <dx-editing
            :allow-updating="true"
            :allow-adding="false"
            :allow-deleting="false"
            mode="batch"
         />
         <dx-header-filter :visible="true" />
         <dx-paging :page-size="20" />
         <dx-pager :show-page-size-selector="true" :show-info="true" />
         <dx-filter-row :visible="true" />

         <dx-column
            data-field="N"
            caption="№ Пропуска"
            :allow-editing="false"
         />
         <dx-column
            data-field="TYPE_OPER"
            caption="Операция"
            :allow-editing="false"
         />
         <dx-column caption="Транспорт" alignment="center">
            <dx-column
               data-field="NUM_TRUCK"
               caption="Номер ТС"
               :allow-editing="false"
            />
            <dx-column
               data-field="MODEL_NAME"
               caption="Марка ТС"
               :allow-editing="false"
            />
            <dx-column
               data-field="DRIVER"
               caption="Водитель"
               :allow-editing="false"
            />
            <dx-column
               data-field="PHONE_NUMBER"
               caption="Телефон"
               :allow-editing="false"
            />
         </dx-column>
         <dx-column caption="Дата" alignment="center">
            <dx-column
               data-field="PLAN_FD_IN"
               caption="Ожидаемая"
               :allow-editing="false"
		data-type="datetime"
		format="dd.MM.yyyy HH:mm:ss"
            />
            <dx-column
               data-field="FD_IN"
               caption="Водитель зарегистрирован"
               :allow-editing="false"
		data-type="datetime"
		format="dd.MM.yyyy HH:mm:ss"
            />
            <dx-column
               data-field="DOC_IN"
               caption="Начало погрузки"
               :allow-editing="false"
		data-type="datetime"
		format="dd.MM.yyyy HH:mm:ss"
            />
            <dx-column
               data-field="PASS_OUT"
               caption="Окончание погрузки/разгрузки"
               :allow-editing="false"
		data-type="datetime"
		format="dd.MM.yyyy HH:mm:ss"
            />
            <dx-column
               data-field="TIME_"
               caption="Разгрузка/погрузка"
               :allow-editing="false"
		data-type="datetime"
		format="dd.MM.yyyy HH:mm:ss"
            />
            <dx-column
               data-field="FD_OUT"
               caption="Выезд"
               :allow-editing="false"
		data-type="datetime"
		format="dd.MM.yyyy HH:mm:ss"
            />
         </dx-column>
         <dx-column data-field="dsc" caption="Комментарий" />
         <dx-master-detail :enabled="true" template="masterDetailTemplate" />
         <template #masterDetailTemplate="{ data }">
            <DetailTemplate :template-data="data" />
         </template>
      </dx-data-grid>
   </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";

import DxDataGrid, {
   DxColumn,
   DxFilterRow,
   DxHeaderFilter,
   DxScrolling,
   DxPager,
   DxPaging,
   DxEditing,
   DxMasterDetail,
   DxExport,
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

import DetailTemplate from "./transport-det.vue";

const store = new CustomStore({
   key: "N",
   load: () => {
      let url =
         "https://lk.aerosibs.ru/wms/dd.php?proc=get_transport&params=" +
         `'{"contr_n":"${window.contr_n}"}'`;
      return fetch(url)
         .then((response) => response.json())
         .then((data) => ({
            data: data,
         }))
         .catch(() => {
            throw new Error("Data Loading Error");
         });
   },
});

export default {
   data() {
      return {
         dataSource: store,
      };
   },
   components: {
      DxDataGrid,
      DxColumn,
      DxFilterRow,
      DxHeaderFilter,
      DxScrolling,
      DxPager,
      DxPaging,
      DxEditing,
      DxMasterDetail,
      DetailTemplate,
      DxExport,
   },
   methods: {
      onExporting(e) {
         const workbook = new Workbook();
         const worksheet = workbook.addWorksheet("Transport");

         exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
         }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
               saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  "Transport.xlsx"
               );
            });
         });
         e.cancel = true;
      },
      onCellPrepared(e) {
         if (e.rowType === "data" /*&& e.column.dataField === "N"*/) {
            if (e.data.PASS_OUT) {
               e.cellElement.style.color = "green";
            } else if (e.data.DOC_IN) {
               e.cellElement.style.color = "yellow";
            } else if (e.data.FD_IN) {
               e.cellElement.style.color = "red";
            }
         }
      },
   },
};
</script>
