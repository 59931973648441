const defaultUser = {
   email: null,
   avatarUrl: null,
   id: null,
   //"https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png",
};

export default {
   _user: null,
   loggedIn() {
      return !!this._user;
   },

   async logIn(email, password) {
      try {
         // Send request
         let response = await fetch(
            "https://lk.aerosibs.ru/wms/auth.php?email='" +
               email +
               "'&pwd=" +
               password
         );
         let responseText = await response.text();
         console.log(responseText);
         if (responseText < 0) {
            throw "r";
         }
         window.contr_n = responseText;
         defaultUser.email = email;
         defaultUser.id = window.contr_n;
         this._user = { ...defaultUser, email };
         return {
            isOk: true,
            data: this._user,
         };
      } catch {
         return {
            isOk: false,
            message: "Authentication failed",
         };
      }
   },

   async logOut() {
      this._user = null;
      window.contr_n = null;
   },

   async getUser() {
      try {
         // Send request

         return {
            isOk: true,
            data: this._user,
         };
      } catch {
         return {
            isOk: false,
         };
      }
   },

   async resetPassword(email) {
      try {
         // Send request
         console.log(email);

         return {
            isOk: true,
         };
      } catch {
         return {
            isOk: false,
            message: "Failed to reset password",
         };
      }
   },

   async changePassword(email, recoveryCode) {
      try {
         // Send request
         console.log(email, recoveryCode);

         return {
            isOk: true,
         };
      } catch {
         return {
            isOk: false,
            message: "Failed to change password",
         };
      }
   },

   async createAccount(email, password) {
      try {
         // Send request
         console.log(email, password);

         return {
            isOk: true,
         };
      } catch {
         return {
            isOk: false,
            message: "Failed to create account",
         };
      }
   },
};
