<template>
   <div>
      <h2 class="content-block">Движение товара</h2>
      <div>
         <div class="settings">
            <div class="field">
               <div class="label">С</div>
               <div class="value">
                  <dx-date-box
                     type="datetime"
                     name="dtFrom"
                     :value="dateFrom"
                     display-format="dd.MM.yy HH:mm"
                     @value-changed="handleValueChange"
                  />
               </div>
            </div>
            <div class="field">
               <div class="label">По</div>
               <div class="value">
                  <dx-date-box
                     type="datetime"
                     name="dtTo"
                     :value="dateTo"
                     display-format="dd.MM.yy HH:mm"
                     @value-changed="handleValueChange"
                  />
               </div>
            </div>
         </div>
      </div>
      <dx-data-grid
         class="dx-card wide-card"
         name="grid"
         :ref="dataGridRefKey"
         key-expr="RN"
         :data-source="dataSource"
         :focused-row-index="0"
         :show-borders="true"
         :focused-row-enabled="true"
         :column-auto-width="true"
         :column-hiding-enabled="false"
         @exporting="onExporting"
      >
         <dx-scrolling mode="standard" column-rendering-mode="virtual" />
         <dx-export :enabled="true" :allow-export-selected-data="false" />
         <dx-editing
            :allow-updating="false"
            :allow-adding="false"
            :allow-deleting="false"
            mode="batch"
         />
         <dx-header-filter :visible="true" />
         <dx-paging :page-size="20" />
         <dx-pager :show-page-size-selector="true" :show-info="true" />
         <dx-filter-row :visible="true" />

         <dx-column caption="Товар" alignment="center" :fixed="true">
            <dx-column data-field="RN" caption="№" :allow-editing="false" />
            <dx-column data-field="CODE" caption="Код" :allow-editing="false" />
            <dx-column
               data-field="ARTK"
               caption="Артикул"
               :allow-editing="false"
            />
            <dx-column
               data-field="NAME"
               caption="Наименование"
               :allow-editing="false"
            />
            <dx-column
               data-field="PART_NUM"
               caption="Партия"
               :allow-editing="false"
            />
            <dx-column caption="Даты" alignment="center">
               <dx-column
                  data-field="MAKE_DATE"
                  caption="Дата Производства"
                  :allow-editing="false"
		data-type="datetime"
		format="dd.MM.yyyy HH:mm:ss"
               />
               <dx-column
                  data-field="EXPIRE_DATE"
                  caption="Срок годности"
                  :allow-editing="false"
		data-type="datetime"
		format="dd.MM.yyyy HH:mm:ss"
               />
            </dx-column>
         </dx-column>
         <dx-column caption="Остатки на начало" alignment="center">
            <dx-column
               data-field="BEGIN_CNT"
               caption="шт"
               :allow-editing="false"
            />
            <dx-column
               data-field="BEGIN_CNT_BRAK"
               caption="брак"
               :allow-editing="false"
            />
            <dx-column
               data-field="BEGIN_PALL"
               caption="паллет"
               :allow-editing="false"
            />
            <dx-column
               data-field="PALL_TYPE_TERM"
               caption="Тип паллет"
               :allow-editing="false"
            />
         </dx-column>
         <dx-column caption="Поступление" alignment="center">
            <dx-column
               data-field="CNT_IN"
               caption="Поступление"
               :allow-editing="false"
            />
            <dx-column
               data-field="CNT_IN_V"
               caption="Неоф."
               :allow-editing="false"
            />
         </dx-column>
         <dx-column caption="Отгрузка" alignment="center">
            <dx-column
               data-field="CNT_OUT"
               caption="Отгрузка"
               :allow-editing="false"
            />
            <dx-column
               data-field="CNT_OUT_V"
               caption="Неоф."
               :allow-editing="false"
            />
         </dx-column>
         <dx-column caption="Остатки на конец" alignment="center">
            <dx-column
               data-field="END_CNT"
               caption="шт. с резервом"
               :allow-editing="false"
            />
            <dx-column
               data-field="END_PALL"
               caption="паллет"
               :allow-editing="false"
            />
            <dx-column
               data-field="CNT_RES_END"
               caption="Резерв"
               :allow-editing="false"
            />
            <dx-column
               data-field="PALL_TYPE_TERM"
               caption="тип паллет"
               :allow-editing="false"
            />
            <dx-column
               data-field="END_CNT_ALL"
               caption="Всего с браком"
               :allow-editing="false"
            />
            <dx-column
               data-field="END_CNT_BRAK"
               caption="Брак"
               :allow-editing="false"
            />
            <dx-column
               data-field="END_FREE_PALL"
               caption="Палл"
               :allow-editing="false"
            />
            <dx-column
               data-field="END_FREE"
               caption="Свободно"
               :allow-editing="false"
            />
         </dx-column>
         <dx-column caption="Доп." alignment="center">
            <dx-column
               data-field="NOM_UNIT_TERM"
               caption="Учётная единица"
               :allow-editing="false"
            />
            <dx-column
               data-field="FD"
               caption="Дата на стоке"
               :allow-editing="false"
		data-type="datetime"
		format="dd.MM.yyyy HH:mm:ss"
            />
         </dx-column>
      </dx-data-grid>
   </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import { DxDateBox } from "devextreme-vue/date-box";
import DxDataGrid, {
   DxColumn,
   DxFilterRow,
   DxHeaderFilter,
   DxScrolling,
   DxPager,
   DxPaging,
   DxEditing,
   DxExport,
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

const dataGridRefKey = "data-grid";
//document.getElementsByName("dtFrom")[0].option({ value: new Date() });
const store = new CustomStore({
   key: "RN",
   load: () => {
      let url =
         "https://lk.aerosibs.ru/wms/dd.php?proc=get_report&params=" +
         `'{"contr_n":"${window.contr_n}","report_name":"nom_move_pall","fd":"${
            document.getElementsByName("dtFrom")[0].value
         }","td":"${document.getElementsByName("dtTo")[0].value}"}'`;
      return fetch(url)
         .then((response) => response.json())
         .then((data) => ({
            data: data,
         }))
         .catch(() => {
            throw new Error("Data Loading Error");
         });
   },
});

export default {
   data() {
      return {
         dataSource: store,
         dateFrom: new Date(
            new Date().setDate(new Date().getDate() - 1)
         ).setHours(0, 0, 0, 0),
         dateTo: new Date().setHours(0, 0, 0, 0) - 1,
         dataGridRefKey,
      };
   },
   computed: {
      dataGrid: function () {
         return this.$refs[dataGridRefKey].instance;
      },
   },
   methods: {
      onExporting(e) {
         const workbook = new Workbook();
         const worksheet = workbook.addWorksheet("Stock");

         exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
         }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
               saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  "Stock.xlsx"
               );
            });
         });
         e.cancel = true;
      },
      handleValueChange() {
         this.dataGrid.refresh();
         //console.log(dataGridInstance);
      },
   },
   components: {
      DxDataGrid,
      DxColumn,
      DxFilterRow,
      DxHeaderFilter,
      DxScrolling,
      DxPager,
      DxPaging,
      DxEditing,
      DxExport,
      DxDateBox,
   },
};
</script>
<style>
.settings {
   display: flex;
   padding: 15px;
}

.settings .column .field {
   padding: 5px 0;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.settings .column .field .label {
   padding-right: 10px;
}

.settings .column .field .value {
   width: 155px;
}
</style>
