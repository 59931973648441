<template>
   <div>
      <h2 class="content-block">Уведомления</h2>

      <dx-data-grid
         id="grid"
         class="dx-card wide-card"
         key-expr="N"
         :data-source="dataSource"
         :focused-row-index="0"
         :show-borders="true"
         :focused-row-enabled="true"
         :column-auto-width="true"
         :column-hiding-enabled="false"
         @exporting="onExporting"
      >
         <dx-scrolling mode="standard" column-rendering-mode="virtual" />
         <dx-export :enabled="true" :allow-export-selected-data="false" />

         <dx-editing
            :allow-updating="true"
            :allow-adding="false"
            :allow-deleting="false"
            mode="batch"
         />
         <dx-header-filter :visible="true" />
         <dx-paging :page-size="40" />
         <dx-pager :show-page-size-selector="true" :show-info="true" />
         <dx-filter-row :visible="true" />

         <dx-column data-field="N" caption="N" :allow-editing="false" />
         <dx-column
            data-field="OPER_NAME"
            caption="Операция"
            :allow-editing="false"
         />
         <dx-column data-field="DT" caption="Момент" :allow-editing="false" />
         <dx-column caption="Товар" alignment="center">
            <dx-column caption="Код" data-field="CODE" :allow-editing="false" />
            <dx-column
               data-field="NAME"
               caption="Наименование"
               :allow-editing="false"
            />
         </dx-column>
         <dx-column
            data-field="CNT"
            caption="Количество"
            :allow-editing="false"
         />
         <dx-column
            data-field="MSG"
            caption="Сообщение"
            :allow-editing="true"
         />
      </dx-data-grid>
   </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";

import DxDataGrid, {
   DxColumn,
   DxFilterRow,
   DxHeaderFilter,
   DxPager,
   DxScrolling,
   DxPaging,
   DxEditing,
   DxExport,
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

const store = new CustomStore({
   key: "N",
   load: () => {
      let url =
         "https://lk.aerosibs.ru/wms/dd.php?proc=get_notifies&params=" +
         `'{"contr_n":"${window.contr_n}"}'`;
      return fetch(url)
         .then((response) => response.json())
         .then((data) => ({
            data: data,
         }))
         .catch(() => {
            throw new Error("Data Loading Error");
         });
   },
   update: (key, values) => {
      console.table(key, values);
      console.log(JSON.stringify(values));
      console.table(key, values.DELIVERY);
      let url =
         "https://lk.aerosibs.ru/wms/dd.php?proc=set_order&params=" +
         `'{"n":"${key}", "values":"${JSON.stringify(values)}"}'`;
      console.log(url);
      return fetch(url)
         .then((response) => response.json())
         .then((data) => ({
            data: data,
         }))
         .catch(() => {
            throw new Error("Data Updating Error");
         });
      //store.update(key, values);
   },
});

const deliveries = [
   { Name: "ASB", N: 1 },
   { Name: "Клиент", N: 2 },
];
export default {
   data() {
      return {
         dataSource: store,
         deliveries,
      };
   },
   methods: {
      onExporting(e) {
         const workbook = new Workbook();
         const worksheet = workbook.addWorksheet("Out");

         exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
         }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
               saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  "Out.xlsx"
               );
            });
         });
         e.cancel = true;
      },
   },
   components: {
      DxDataGrid,
      DxColumn,
      DxFilterRow,
      DxHeaderFilter,
      DxPager,
      DxScrolling,
      DxPaging,
      DxEditing,
      DxExport,
   },
};
</script>
