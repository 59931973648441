export default [
   {
      text: "Home",
      path: "/home",
      icon: "home",
   },
   {
      text: "Склад Аэросиб",
      icon: "folder",
      items: [
         {
            text: "Уведомления",
            path: "/Notify",
         },
         {
            text: "Наличие на складе",
            path: "/StockSum",
         },
         {
            text: "Наличие на складе с адресами",
            path: "/Stock",
         },
         {
            text: "Приходы",
            path: "/In",
         },
         {
            text: "Заказы",
            path: "/Orders",
         },
         {
            text: "Транспорт",
            path: "/Transport",
         },
         {
            text: "Отчёты",
            icon: "folder",
            items: [
               {
                  text: "Движение номенклатуры",
                  path: "/rep_nom_move",
               },
               {
                  text: "Движение номенклатуры с паллетами",
                  path: "/rep_nom_move_pall",
               },
               {
                  text: "Отчёт о браке",
                  path: "/dmg_report",
               },
            ],
         },
      ],
   },
];
