<template>
   <div>
      <h2 class="content-block">Остатки с адресами</h2>
      <div>
         <div class="settings">
            <div class="field">
               <div class="label">Дата</div>
               <div class="value">
                  <dx-date-box
                     type="datetime"
                     name="dtDate"
                     :value="date"
                     display-format="dd.MM.yy HH:mm"
                     @value-changed="handleValueChange"
                  />
               </div>
            </div>
         </div>
      </div>

      <dx-data-grid
         class="dx-card wide-card"
         name="grid"
         :ref="dataGridRefKey"
         key-expr="CODE"
         :data-source="dataSource"
         :focused-row-index="0"
         :show-borders="true"
         :focused-row-enabled="true"
         :column-auto-width="false"
         :column-hiding-enabled="false"
         @exporting="onExporting"
      >
         <dx-scrolling mode="standard" column-rendering-mode="virtual" />
         <dx-export :enabled="true" :allow-export-selected-data="false" />
         <dx-editing
            :allow-updating="false"
            :allow-adding="false"
            :allow-deleting="false"
            mode="batch"
         />
         <dx-header-filter :visible="true" />
         <dx-paging :page-size="50" />
         <dx-pager :show-page-size-selector="true" :show-info="true" />
         <dx-filter-row :visible="true" />

         <dx-column caption="Товар" alignment="center" :fixed="true">
            <dx-column
               data-field="CODE"
               caption="Код"
               :allow-editing="false"
               :width="150"
            />
            <dx-column
               data-field="ARTK"
               caption="Артикул"
               :allow-editing="false"
               :width="150"
            />
            <dx-column
               data-field="NAME"
               caption="Наименование"
               :allow-editing="false"
            />
         </dx-column>
         <dx-column
            data-field="PART_NUM"
            caption="Партия"
            :allow-editing="false"
            :width="150"
         />
         <dx-column
            data-field="EXPIRE_DATE"
            caption="Срок"
            :allow-editing="false"
            data-type="datetime"
            format="dd.MM.yyyy HH:mm:ss"
            :width="150"
         />
         <dx-column caption="Состояние" alignment="center">
            <dx-column
               data-field="NOM_TYP"
               caption="Состояние"
               :allow-editing="false"
               :column-auto-width="true"
               :width="150"
            />
            <dx-column
               data-field="SUB_NOM_TYP"
               caption="Градация"
               :allow-editing="false"
               :column-auto-width="true"
            />
         </dx-column>
         <dx-column caption="Хранение" alignment="center">
            <dx-column
               data-field="STORE_N"
               caption="Код склада"
               :allow-editing="false"
               :column-auto-width="true"
               :width="70"
            />
            <dx-column
               data-field="ADDR"
               caption="Адрес"
               :allow-editing="false"
               :column-auto-width="true"
               :width="200"
            />
            <dx-column
               data-field="ST_PALL_N"
               caption="Паллета"
               :allow-editing="false"
               :column-auto-width="true"
               :width="100"
            />
            <dx-column
               data-field="PALL_TYPE_NAME"
               caption="Тип паллеты"
               :allow-editing="false"
               :column-auto-width="true"
            />
         </dx-column>
         <dx-column
            caption="Кол-во"
            alignment="center"
            :column-auto-width="true"
         >
            <dx-column
               data-field="CNT_TOTAL"
               caption="Всего"
               :allow-editing="false"
               :column-auto-width="true"
               :width="80"
            />
            <dx-column
               data-field="CNT_RESERVE"
               caption="В том числе в резерве"
               :allow-editing="false"
               :column-auto-width="true"
               :width="80"
            />
            <dx-column
               data-field="CNT_FREE"
               caption="Свободно"
               :allow-editing="false"
               :column-auto-width="true"
               :width="80"
            />
         </dx-column>
      </dx-data-grid>
   </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import { DxDateBox } from "devextreme-vue/date-box";

import DxDataGrid, {
   DxColumn,
   DxFilterRow,
   DxHeaderFilter,
   DxScrolling,
   DxPager,
   DxPaging,
   DxEditing,
   DxExport,
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
const dataGridRefKey = "data-grid";
const store = new CustomStore({
   key: "CODE",
   load: () => {
      let url =
         "https://lk.aerosibs.ru/wms/dd.php?proc=get_stock&params=" +
         `'{"contr_n":"${window.contr_n}","dt":"${
            document.getElementsByName("dtDate")[0].value
         }"}'`;
      return fetch(url)
         .then((response) => response.json())
         .then((data) => ({
            data: data,
         }))
         .catch(() => {
            throw new Error("Data Loading Error");
         });
   },
});

export default {
   data() {
      return {
         dataSource: store,
         date: new Date(),
         dataGridRefKey,
      };
   },
   computed: {
      dataGrid: function () {
         return this.$refs[dataGridRefKey].instance;
      },
   },
   methods: {
      onExporting(e) {
         const workbook = new Workbook();
         const worksheet = workbook.addWorksheet("Stock");

         exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
         }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
               saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  "Stock.xlsx"
               );
            });
         });
         e.cancel = true;
      },
      handleValueChange() {
         this.dataGrid.refresh();
      },
   },
   components: {
      DxDataGrid,
      DxColumn,
      DxFilterRow,
      DxHeaderFilter,
      DxScrolling,
      DxPager,
      DxPaging,
      DxEditing,
      DxExport,
      DxDateBox,
   },
};
</script>
