<template>
   <div>
      <dx-data-grid
         class="dx-card wide-card"
         key-expr="LINK_N"
         :data-source="dataSource"
         :focused-row-index="0"
         :show-borders="false"
         :focused-row-enabled="true"
         :column-auto-width="true"
         :column-hiding-enabled="true"
      >
         <dx-editing
            :allow-updating="false"
            :allow-adding="false"
            :allow-deleting="false"
            mode="batch"
         />
         <dx-header-filter :visible="true" />
         <dx-paging :page-size="20" />
         <dx-pager :show-page-size-selector="true" :show-info="true" />
         <dx-filter-row :visible="true" />

         <dx-column data-field="LINK_N" caption="N" :allow-editing="false" />
         <dx-column
            data-field="NUM_DOC"
            caption="Номер Документа"
            :allow-editing="false"
         />
         <dx-column
            data-field="CC_NAME"
            caption="Заказчик"
            :allow-editing="false"
         />
      </dx-data-grid>
   </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";

import DxDataGrid, {
   DxColumn,
   DxFilterRow,
   DxHeaderFilter,
   DxPager,
   DxPaging,
   DxEditing,
} from "devextreme-vue/data-grid";

export default {
   props: {
      templateData: {
         type: Object,
         default: () => {},
      },
   },
   data() {
      console.log(this.templateData.data.N);
      return {
         dataSource: new CustomStore({
            key: "LINK_N",
            load: () => {
               let url =
                  "https://lk.aerosibs.ru/wms/dd.php?proc=get_transport_det&params=" +
                  `'{"stream_n":"${this.templateData.data.N}"}'`;
               return fetch(url)
                  .then((response) => response.json())
                  .then((data) => ({
                     data: data,
                  }))
                  .catch(() => {
                     throw new Error("Data Loading Error");
                  });
            },
         }),
      };
   },
   components: {
      DxDataGrid,
      DxColumn,
      DxFilterRow,
      DxHeaderFilter,
      DxPager,
      DxPaging,
      DxEditing,
   },
};
</script>
