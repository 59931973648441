<template>
   <div>
      <h2 class="content-block">Приходы</h2>

      <dx-data-grid
         id="grid"
         class="dx-card wide-card"
         key-expr="N"
         :data-source="dataSource"
         :focused-row-index="0"
         :show-borders="true"
         :column-auto-width="true"
         :focused-row-enabled="true"
         :column-hiding-enabled="false"
         @exporting="onExporting"
      >
         <dx-scrolling column-rendering-mode="virtual" />
         <dx-export :enabled="true" :allow-export-selected-data="false" />

         <dx-editing
            :allow-updating="true"
            :allow-adding="false"
            :allow-deleting="false"
            mode="batch"
         />
         <dx-header-filter :visible="true" />
         <dx-paging :page-size="50" />
         <dx-pager :show-page-size-selector="true" :show-info="true" />
         <dx-filter-row :visible="true" />

         <dx-column data-field="N" caption="№ Аэросиб" :allow-editing="false" />
         <dx-column caption="Документ" alignment="center">
            <dx-column
               data-field="NUM_INVOICE"
               caption="Ваш № Документа"
               :allow-editing="false"
            />
            <dx-column
               data-field="INVOICE_DATE"
               caption="Планируемая Дата прибытия"
               :allow-editing="false"
               data-type="datetime"
               format="dd.MM.yyyy HH:mm:ss"
            />
            <dx-column
               data-field="DOC_OTHER"
               caption="Документ 2"
               :allow-editing="false"
            />
         </dx-column>
         <dx-column caption="Информация" alignment="center">
            <dx-column
               data-field="DD_STATUS_TERM"
               caption="Статус"
               :allow-editing="false"
            />
            <dx-column
               data-field="CC_NAME"
               caption="Поставщик"
               :allow-editing="false"
            />
            <dx-column
               data-field="DSC"
               caption="Примечание"
               :allow-editing="false"
            />
         </dx-column>
         <dx-column caption="Дата" alignment="center">
            <dx-column
               data-field="FD"
               caption="Документ создан"
               :allow-editing="false"
               data-type="datetime"
               format="dd.MM.yyyy HH:mm:ss"
            />
            <dx-column
               data-field="TIME_IN"
               caption="Прибытие т/с"
               :allow-editing="false"
               data-type="datetime"
               format="dd.MM.yyyy HH:mm:ss"
            />
            <dx-column
               data-field="PUT_ON_STORE_FD"
               caption="Оприходован на склад"
               :allow-editing="false"
               data-type="datetime"
               format="dd.MM.yyyy HH:mm:ss"
            />
         </dx-column>
         <dx-column caption="%%" alignment="center">
            <dx-column
               data-field="JOBS_PCT"
               caption="Приёмка"
               :allow-editing="false"
            />
            <dx-column
               data-field="JOBS_PUT_PCT"
               caption="Расстановка"
               :allow-editing="false"
            />
         </dx-column>
         <dx-column caption="Расхождение" alignment="center">
            <dx-column
               data-field="DIFF_PLUS"
               caption="Излишек"
               :allow-editing="false"
               :width="200"
            />
            <dx-column
               data-field="DIFF_MINUS"
               caption="Недостаток"
               :allow-editing="false"
               :width="200"
            />
            <dx-column
               data-field="BRACK"
               caption="Брак"
               :allow-editing="false"
               :width="200"
            />
         </dx-column>
      </dx-data-grid>
   </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";

import DxDataGrid, {
   DxColumn,
   DxFilterRow,
   DxHeaderFilter,
   DxPager,
   DxScrolling,
   DxPaging,
   DxEditing,
   DxExport,
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

const store = new CustomStore({
   key: "N",
   load: () => {
      let url =
         "https://lk.aerosibs.ru/wms/dd.php?proc=get_in&params=" +
         `'{"contr_n":"${window.contr_n}"}'`;
      return fetch(url)
         .then((response) => response.json())
         .then((data) => ({
            data: data,
         }))
         .catch(() => {
            throw new Error("Data Loading Error");
         });
   },
   update: (key, values) => {
      console.table(key, values);
      console.table(key, values.delivery);
      fetch(
         "https://10.10.10.180/data/orders/set_delivery.php?p_n=" +
            key +
            "&p_delivery_n=" +
            values.delivery
      ).catch(() => {
         throw new Error("Data Updating Error");
      });
      //store.update(key, values);
   },
});

export default {
   data() {
      return {
         dataSource: store,
      };
   },
   methods: {
      onExporting(e) {
         const workbook = new Workbook();
         const worksheet = workbook.addWorksheet("In");

         exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
         }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
               saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  "In.xlsx"
               );
            });
         });
         e.cancel = true;
      },
   },
   components: {
      DxDataGrid,
      DxColumn,
      DxFilterRow,
      DxHeaderFilter,
      DxPager,
      DxScrolling,
      DxPaging,
      DxEditing,
      DxExport,
   },
};
</script>
